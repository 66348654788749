.logo {
	@apply text-2xl font-bold text-purple-600;
}

.navLink {
	@apply text-gray-500 font-semibold hover:text-purple-600 transition-colors duration-200;
}

.navLinkActive {
	@apply text-purple-600;
}

.settingsButton {
	@apply p-2 rounded-lg bg-gray-200 hover:bg-gray-300 transition-colors duration-200;
}
