.document {
	@apply bg-white border border-purple-300 p-3 rounded-lg;
}

.autoFixButton {
	@apply bg-white border border-purple-600 text-purple-600 font-semibold px-3 py-1 rounded-lg hover:bg-gray-300 transition-colors duration-200;
}

.requestReviewButton {
	background-color: #6c63ff;
	color: white;
	border: none;
	padding: 8px 12px;
	border-radius: 4px;
	cursor: pointer;
}

.fixAllButton {
	background-color: #6c63ff;
	color: white;
	border: none;
	padding: 8px 12px;
	border-radius: 4px;
	margin-left: 8px;
	cursor: pointer;
}

.checklist {
	list-style: none;
	padding: 0;
}

.checklist li {
	display: flex;
	align-items: center;
}

.summary {
	text-align: right;
}
