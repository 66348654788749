.document {
	@apply bg-purple-100 border border-purple-200 p-3 rounded-lg mb-4;
}

.dropdown {
	@apply bg-white rounded-lg shadow text-black p-3 mb-4;
}

.contextTextarea {
	@apply w-full h-32 border rounded-lg p-2 resize-none focus:ring-2 focus:ring-purple-600 focus:border-transparent;
}

.checkButton {
	@apply bg-white border border-purple-600 text-purple-600 px-3 py-1 rounded-lg mr-2 hover:bg-gray-300 transition-colors duration-200;
}

.checkContentButton {
	@apply w-full bg-purple-600 text-white px-3 py-1 rounded-lg hover:bg-purple-700 transition-colors duration-200;
}

.updateFileButton {
	@apply w-full bg-purple-600 text-white px-3 py-1 rounded-lg hover:bg-purple-700 transition-colors duration-200;
}

.checklist {
	@apply bg-white border border-purple-300 p-3 rounded-lg mb-4 text-black;
}

.checklistItem {
	@apply flex items-center mb-2;
}

.checklistIcon {
	@apply h-5 w-5 text-purple-600 mr-2;
}

.updatedDocument {
	@apply bg-gray-100 border border-gray-300 p-3 rounded-lg mb-4;
}
